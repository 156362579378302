import { MutableRefObject, useEffect, useRef } from 'react';
import type {
  TUseCodeInputOnChange,
  TUseCodeInputReturn,
} from '../CodeInput.types';

export const useCodeInput = (
  length: number,
  onChange: TUseCodeInputOnChange,
): TUseCodeInputReturn => {
  const internalValue = useRef<string[]>(new Array(length).fill(''));
  const inputElementsRef = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    function onPaste(event: Event): void {
      if (!isClipboardEvent(event)) {
        return;
      }

      const res = handlePasteEvent(event, internalValue, inputElementsRef);

      if (onChange) {
        onChange(res);
      }
    }

    window.addEventListener('paste', onPaste);

    return () => window.removeEventListener('paste', onPaste);
  }, [onChange]);

  const manageFocus = (focusedInputIndex: number): void => {
    const inputToFocusIndex = internalValue.current.findIndex(
      value => value === '',
    );

    if (inputToFocusIndex === -1 || !inputElementsRef.current.length) {
      return inputElementsRef.current[focusedInputIndex]?.blur();
    }

    inputElementsRef.current[inputToFocusIndex]?.focus();
  };

  function handleChange(index: number): void {
    internalValue.current[index] = inputElementsRef.current[index]?.value || '';
    manageFocus(index);

    if (onChange) {
      onChange(internalValue.current.join(''));
    }
  }

  return {
    handleChange,
    internalValue,
    inputElementsRef,
  };
};

export const handlePasteEvent = (
  event: ClipboardEvent,
  internalValue: MutableRefObject<string[]>,
  inputElementsRef: MutableRefObject<Array<HTMLInputElement | null>>,
): string => {
  const evt = event;
  const data = evt.clipboardData?.getData('text') ?? '';
  const dataWithoutSpaces = data.replace(/\s/g, '');

  dataWithoutSpaces.split('').forEach((char, index) => {
    const elem: HTMLInputElement | null | undefined =
      inputElementsRef.current[index];

    if (!isNaN(Number(char)) && elem) {
      elem.value = char;
      internalValue.current[index] = char;
    }

    elem?.blur();
  });

  return internalValue.current.join('');
};

const isClipboardEvent = (event: Event): event is ClipboardEvent =>
  'clipboardData' in event;
