import i18next from 'i18next';

class Horserace {
  constructor() {
    this.horsemap = this.getHorseraceMap();
  }

  getHorseraceMap = () => ({
    seen_by_client: name => ({
      type: 'warning',
      tag: {
        leftIcon: 'icn-show-24',
        children: i18next.t('interviewing.candidateEvents.seen_by_client'),
      },
      additional_info: {
        type: 'action',
        text: i18next.t('interviewing.candidateEvents.seen_by_client_text'),
      },
    }),
    presented: name => ({
      type: 'warning',
      tag: {
        leftIcon: 'icn-hide-24',
        children: i18next.t('interviewing.candidateEvents.presented'),
      },
      additional_info: {
        type: 'action',
        text: i18next.t('interviewing.candidateEvents.presented_text'),
      },
    }),
    liked_by_client: name => ({
      type: 'positive',
      tag: {
        leftIcon: 'icn-bulb-24',
        children: i18next.t('interviewing.candidateEvents.liked_by_client'),
      },
    }),
    disliked_by_client: name => ({
      type: 'negative',
      tag: {
        leftIcon: 'icn-minus-circle-24',
        children: i18next.t('interviewing.candidateEvents.disliked_by_client'),
      },
    }),
    technical_report_incoming: name => ({
      type: 'default',
      tag: {
        leftIcon: 'icn-folder-open-24',
        children: i18next.t(
          'interviewing.candidateEvents.technical_report_incoming',
        ),
        type: 'default',
      },
    }),
    technical_report_published: name => ({
      type: 'warning',
      tag: {
        leftIcon: 'icn-file-blank-outline-24',
        children: i18next.t(
          'interviewing.candidateEvents.technical_report_published',
        ),
      },
      additional_info: {
        type: 'action',
        text: i18next.t(
          'interviewing.candidateEvents.technical_report_published_text',
        ),
      },
    }),
    technical_report_filled_in: name => ({
      type: 'default',
      tag: {
        leftIcon: 'icn-file-blank-outline-24',
        children: i18next.t(
          'interviewing.candidateEvents.technical_report_filled_in',
        ),
      },
    }),
    client_interview_to_be_scheduled: name => ({
      type: 'default',
      tag: {
        leftIcon: 'icn-group-alt-24',
        children: i18next.t(
          'interviewing.candidateEvents.client_interview_to_be_scheduled',
        ),
      },
      additional_info: {
        type: 'text',
        text: i18next.t(
          'interviewing.candidateEvents.client_interview_to_be_scheduled_text',
        ),
      },
    }),
    client_interview_incoming: name => ({
      type: 'warning',
      tag: {
        leftIcon: 'icn-group-alt-24',
        children: i18next.t(
          'interviewing.candidateEvents.client_interview_incoming',
        ),
      },
      additional_info: {
        type: 'action',
        text: i18next.t(
          'interviewing.candidateEvents.client_interview_incoming_text',
        ),
      },
    }),
    client_interview_done: name => ({
      type: 'default',
      tag: {
        leftIcon: 'icn-group-alt-24',
        children: i18next.t(
          'interviewing.candidateEvents.client_interview_done',
        ),
      },
    }),
    offer_incoming: name => ({
      type: 'default',
      tag: {
        leftIcon: 'icn-money-24',
        children: i18next.t('interviewing.candidateEvents.offer_incoming'),
      },
      additional_info: {
        type: 'text',
        text: i18next.t('interviewing.candidateEvents.offer_incoming_text'),
      },
    }),
    offer_made: name => ({
      type: 'default',
      tag: {
        leftIcon: 'icn-handshake-24',
        children: i18next.t('interviewing.candidateEvents.offer_made'),
      },
      additional_info: {
        type: 'text',
        text: i18next.t('interviewing.candidateEvents.offer_made_text', {
          name,
        }),
      },
    }),
    offer_accepted: name => ({
      type: 'finish',
    }),
    offer_declined: name => ({
      type: 'negative',
      tag: {
        leftIcon: 'icn-sad-24',
        children: i18next.t('interviewing.candidateEvents.offer_declined'),
      },
    }),
    candidate_withdrew: name => ({
      type: 'negative',
      tag: {
        leftIcon: 'icn-user-close-24',
        children: i18next.t('interviewing.candidateEvents.candidate_withdrew'),
      },
    }),
    candidate_rejected: name => ({
      type: 'negative',
      tag: {
        leftIcon: 'icn-sad-24',
        children: i18next.t('interviewing.candidateEvents.candidate_rejected'),
      },
    }),
  });

  getHorsecard = ({ type, name }) => {
    if (type) {
      return this.horsemap[type](name);
    } else {
      return {
        type: 'default',
        tag: null,
        additional_info: null,
      };
    }
  };
}

export default Horserace;
