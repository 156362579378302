import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import candidate from './candidate';
import session from './session';
import showcaseTable from './showcase-table';

import { GTM } from '../gtm';
import Sentry from '../sentry';
import { ModelApplications } from './Applications';
import { ModelDeal } from './Deal';
import { ModelCustomer } from './Customer';
import { ModelShare } from './Share';
import { ModelUser } from './User';
import { DEAL_PAGE_STATES } from '../constants/deal';
import { USER_TYPES_MAP } from '../constants/session';
import { utilityChangeLocale } from '../utils/locale';

export default {
  // global state
  initialized: false,
  currentRoute: null,
  currentStatus: DEAL_PAGE_STATES.DOING,
  authenticationError: null,
  appError: null,
  modalId: null,

  initialize: thunk(
    async (
      actions,
      { dealId, isShareModalOpen },
      { getStoreActions, getStoreState },
    ) => {
      try {
        const {
          session: { authenticate },
          user: { fetchUser },
          deal: { fetchDeal, setDealId },
          share: { fetchShares },
          applications: { thunkFetchApplications },
          showcaseTable: { initTable },
        } = getStoreActions();

        setDealId(dealId);

        await utilityChangeLocale();

        if (isShareModalOpen) {
          actions.setModalId('share');
          actions.setModalOpened(true);
        }

        if (!dealId) {
          try {
            await authenticate();
          } catch (_error) {
            return;
          }

          return await fetchUser();
        }

        try {
          await fetchShares();
          await fetchDeal(dealId);
        } catch (_error) {
          return;
        }

        await fetchUser();
        await thunkFetchApplications(dealId);

        initTable();

        const {
          customer: { companyName },
          deal: { jobTitle, jobId },
          user: { type: userType, email },
        } = getStoreState();

        if (companyName && dealId && jobTitle && userType) {
          const accessType = !jobId ? 'prospetto' : 'cliente';

          const eventAction = `Accesso ${accessType}`;

          GTM.trackGTMEvent({
            action: eventAction,
            label: `${companyName} - ${dealId} - ${jobTitle}`,
          });

          GTM.trackGA4Event(
            eventAction,
            companyName,
            `${dealId} - ${jobTitle}`,
          );

          Sentry.setUser({ userType, email });
        }
      } finally {
        actions.setInitialized(true);
      }
    },
  ),

  // actions
  setInitialized: action((state, payload) => {
    state.initialized = payload;
  }),

  setAuthenticationError: action((state, payload) => {
    state.authenticationError = payload;
  }),

  setCurrentRoute: action((state, payload) => {
    state.currentRoute = payload;
  }),

  setCurrentStatus: action((state, payload) => {
    state.currentStatus = payload;
  }),

  setModalOpened: action((state, payload) => {
    state.isModalOpened = payload;
  }),
  setModalId: action((state, payload) => {
    state.modalId = payload;
  }),

  setAppError: action((state, error) => {
    state.appError = error;
  }),

  updateDocumentTitle: thunk(
    async (actions, payload, { getState, dispatch }) => {
      const { currentRoute, deal } = getState();
      const step = i18next.t(`general.document_title.${currentRoute}`);

      document.title = `${step} - ${deal.jobTitle} - Reverse`;
    },
  ),
  changeLanguage: thunk(
    async (actions, selectedLanguage, { getStoreActions }) => {
      try {
        const {
          session: { authFetch },
        } = getStoreActions();
        await authFetch({
          endpoint: `/v1/auth/users/me/locale/${selectedLanguage}`,
          requestOptions: { method: 'PUT' },
        });
        window.location.reload();
      } catch (error) {
        await utilityChangeLocale(error.locale);
      }
    },
  ),
  // models
  applications: ModelApplications,
  customer: ModelCustomer,
  deal: ModelDeal,
  share: ModelShare,
  user: ModelUser,
  candidate,
  session,
  showcaseTable,
};

export const selectorRootActions = actions => actions;
export const selectorRootState = state => state;
