export const INTERVIEWING_EN = {
  header: {
    title: 'Interviews',
    subtitle:
      'Here there will be the list of candidates. You will always be able to check their current status and give feedback to make them move forward in the selection process.',
    subtitle_bis:
      'Here you will find the list of candidates. You can always see what stage they are at and give feedback to advance them in the selection process.',
    alert:
      "There are people in the final phase. <strong>Resolve doubts quickly</strong> if you don't want to miss them.",
  },
  referenceHeader: {
    title: 'Your contacts',
  },
  tabs: {
    see: 'See',
    all: 'All',
    active: 'In process',
    discarded: 'Do not proceed',
  },
  candidateEvents: {
    seen_by_client: 'CV seen',
    seen_by_client_text: 'Leave a feedback',
    presented: 'CV not seen',
    presented_text: 'Read and leave a feedback',
    liked_by_client: 'Interesting',
    disliked_by_client: 'Not interesting',
    technical_report_incoming: 'Waiting for the report',
    technical_report_published: 'Report published',
    technical_report_published_text: 'Read report',
    technical_report_filled_in: 'Report published',
    client_interview_to_be_scheduled: 'To interview',
    client_interview_to_be_scheduled_text: 'Being organized',
    client_interview_incoming: 'To interview',
    client_interview_incoming_text: "Download interview's dossier",
    client_interview_done: 'Interviewed',
    offer_incoming: 'Make an offer',
    offer_incoming_text: "Let's not lose him/her!",
    offer_made: 'Offered!',
    offer_made_text: 'waiting for {{ name }}',
    offer_accepted: 'Winner',
    offer_declined: 'Offer declined',
    candidate_withdrew: 'Application withdrawn',
    candidate_rejected: "Won't proceed",
  },
};
