import { action, thunk } from 'easy-peasy';
import { utilityGetErrorInstance, utilityThrowError } from '../utils/errors';

export const ModelShare = {
  loading: true,
  error: false,
  shares: [],

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setShares: action((state, payload) => {
    state.shares = payload;
  }),

  fetchShares: thunk(async (actions, _, { getStoreState, getStoreActions }) => {
    actions.setLoading(true);
    actions.setError(false);
    actions.setShares([]);

    const { deal } = getStoreState();

    const {
      session: { authFetch },
    } = getStoreActions();

    try {
      const { shares } = await authFetch({
        endpoint: `/v1/employer-view/deals/${deal.id}/shares`,
      });

      actions.setShares(shares);
    } catch (error) {
      if (error?.cause?.status === 404) {
        return;
      }

      actions.setError(true);
      utilityThrowError(error, "Share model failed to fetch deal's shares.");
    } finally {
      actions.setLoading(false);
    }
  }),

  postShare: thunk(
    async (actions, payload, { getState, getStoreState, getStoreActions }) => {
      const { deal } = getStoreState();

      const {
        session: { authFetch },
      } = getStoreActions();

      try {
        const { shares: updatedShares } = await authFetch({
          endpoint: `/v1/employer-view/deals/${deal.id}/shares`,
          requestOptions: {
            body: JSON.stringify(payload),
            method: 'POST',
          },
        });

        if (updatedShares) {
          return actions.setShares(updatedShares);
        }
      } catch (error) {
        return Promise.reject(
          utilityGetErrorInstance(
            error,
            'Share model failed to post a share request.',
          ),
        );
      }
    },
  ),

  removeShare: thunk(
    async (actions, shareId, { getStoreState, getStoreActions }) => {
      const { deal } = getStoreState();

      const {
        session: { authFetch },
      } = getStoreActions();

      try {
        const { shares: updatedShares } = await authFetch({
          endpoint: `/v1/employer-view/deals/${deal.id}/shares/${shareId}`,
          requestOptions: {
            method: 'DELETE',
          },
        });

        if (updatedShares) {
          return actions.setShares(updatedShares);
        }
      } catch (error) {
        utilityThrowError(error, 'Share model failed to remove a share.');
      }
    },
  ),
};

export const selectorShareState = state => state.share.shares;
export const selectorShareActions = actions => actions.share;
