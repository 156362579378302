export const INTERVIEWING_IT = {
  header: {
    title: 'Colloqui',
    subtitle:
      'Qui trovi la lista dei candidati. Puoi sempre visualizzare la fase in cui si trovano e dare feedback per farli avanzare nel percorso di selezione.',
    'subtitle_to-do':
      'Qui ci sarà la lista dei candidati. Potrai sempre visualizzare la fase in cui si troveranno e dare feedback per farli avanzare nel percorso di selezione.',
    alert:
      'Ci sono persone nella fase finale, per non fartele sfuggire <strong>risolvi i dubbi in fretta</strong>',
  },
  referenceHeader: {
    title: 'I tuoi referenti',
  },
  tabs: {
    see: 'Visualizza per',
    all: 'Tutti',
    active: 'In selezione',
    discarded: 'Non proseguono',
  },
  candidateEvents: {
    seen_by_client: 'Curriculum visto',
    seen_by_client_text: 'Lascia un feedback',
    presented: 'Curriculum non visto',
    presented_text: 'Leggi e lascia un feedback',
    liked_by_client: 'Interessante',
    disliked_by_client: 'Non interessante',
    technical_report_incoming: 'In attesa del report',
    technical_report_published: 'Report pubblicato',
    technical_report_published_text: 'Leggi report',
    technical_report_filled_in: 'Report pubblicato',
    client_interview_to_be_scheduled: 'Da colloquiare',
    client_interview_to_be_scheduled_text: 'in fase di organizzazione...',
    client_interview_incoming: 'Da colloquiare',
    client_interview_incoming_text: 'Scarica dossier colloquio',
    client_interview_done: 'Colloquio avvenuto',
    offer_incoming: 'Fare offerta',
    offer_incoming_text: 'Evitiamo che ci sfugga!',
    offer_made: 'Offerta fatta',
    offer_made_text: 'in attesa di {{ name }}',
    offer_accepted: 'Winner',
    offer_declined: 'Offerta rifiutata',
    candidate_withdrew: 'Si ritira',
    candidate_rejected: 'Non prosegue',
  },
};
