export const Benefits = {
  CAR: 'car',
  CANTEEN: 'canteen',
  TRAINING: 'training',
  WELFARE: 'welfare',
  MEAL_VOUCHERS: 'meal_vouchers',
  INSURANCE: 'insurance',
  SMARTPHONE: 'smartphone',
  REMOTE_WORKING: 'remote_working',
  PC: 'personal_computer',
};

export const BenefitIcons = {
  [Benefits.CANTEEN]: 'icn-coffee-togo-outline-24',
  [Benefits.CAR]: 'icn-car-outline-24',
  [Benefits.TRAINING]: 'icn-group-alt-outline-24',
  [Benefits.WELFARE]: 'icn-home-heart-outline-24',
  [Benefits.MEAL_VOUCHERS]: 'icn-credit-card-outline-24',
  [Benefits.INSURANCE]: 'icn-user-heart-outline-24',
  [Benefits.SMARTPHONE]: 'icn-phone-outline-24',
  [Benefits.REMOTE_WORKING]: 'icn-home-outline-24',
  [Benefits.PC]: 'icn-laptop-outline-24',
};

export const MotivationValues = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const MotivationIcons = {
  [MotivationValues.HIGH]: 'icn-super-happy-24',
  [MotivationValues.LOW]: 'icn-confused-24',
  [MotivationValues.MEDIUM]: 'icn-happy-24',
};

export const CANDIDATE_EVENT_TYPES = {
  PRESENTED: 'presented',
  SEEN_BY_CLIENT: 'seen_by_client',
  LIKED_BY_CLIENT: 'liked_by_client',
  DISLIKED_BY_CLIENT: 'disliked_by_client',
  TECHNICAL_REPORT_INCOMING: 'technical_report_incoming',
  TECHNICAL_REPORT_FILLED_IN: 'technical_report_filled_in',
  TECHNICAL_REPORT_PUBLISHED: 'technical_report_published',
  CLIENT_INTERVIEW_TO_BE_SCHEDULED: 'client_interview_to_be_scheduled',
  CLIENT_INTERVIEW_INCOMING: 'client_interview_incoming',
  CLIENT_INTERVIEW_DONE: 'client_interview_done',
  OFFER_INCOMING: 'offer_incoming',
  OFFER_MADE: 'offer_made',
  OFFER_ACCEPTED: 'offer_accepted',
  OFFER_DECLINED: 'offer_declined',
  CANDIDATE_WITHDREW: 'candidate_withdrew',
  CANDIDATE_REJECTED: 'candidate_rejected',
};
