export const INTERVIEWING_ES = {
  header: {
    title: 'Entrevistas',
    subtitle:
      'Aquí encontrarás la lista de candidatos. Siempre podrás visualizar en qué fase se encuentran y compartir tus comentarios para que avancen en el proceso de selección.',
    subtitle_bis:
      'Aquí puedes ver la lista de candidatos. Puedes comprobar en qué fase se encuentran y compartir tus comentarios para que avancen a la siguiente fase.',
    alert:
      'Hay personas en la fase final. Para que no abandonen el proceso, <strong>resuelve las dudas con premura.</strong>',
  },
  referenceHeader: {
    title: 'Tus personas de contacto',
  },
  tabs: {
    see: '',
    all: '',
    active: '',
    discarded: '',
  },
  eligibles: {
    seen_by_client: 'Curriculum visto',
    seen_by_client_text: 'Dejar un comentario',
    presented: 'Currículum no visto',
    presented_text: 'Leer y dejar un comentario',
    liked_by_client: 'Interesante',
    disliked_by_client: 'No interesante',
    technical_report_incoming: 'A la espera del informe',
    technical_report_published: 'Informe publicado',
    technical_report_published_text_text: 'Leer informe',
    technical_report_filled_in: 'Informe publicado',
    client_interview_to_be_scheduled: 'Por entrevistar',
    client_interview_to_be_scheduled_text: 'En fase de organización...',
    client_interview_incoming: 'Por entrevistar',
    client_interview_incoming_text: 'Descargar dossier de la entrevista',
    client_interview_done: 'Entrevista realizada',
    offer_incoming: 'Hacer una oferta',
    offer_incoming_text: '¡No permitas que abandone el proceso!',
    offer_made: 'Oferta realizada',
    offer_made_text: 'A la espera de {{ name }}',
    offer_accepted: '¡Winner!',
    offer_declined: 'Oferta rechazada',
    candidate_withdrew: 'Se retira',
    candidate_rejected: 'No sigue adelante',
  },
};
