import type { FC } from 'react';
import React from 'react';
import type { TShare } from '../../../models/Share';
import { ShareLinkModalListItem } from './ShareLinkModalListItem';
import { useStoreState } from 'easy-peasy';
import { selectorShareState } from '../../../model/Share';

export const ShareLinkModalList: FC = () => {
  const shares: TShare[] = useStoreState(selectorShareState);

  return (
    <div className="c-share-link-modal__list">
      <table
        className="c-share-link-modal__list-table"
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          {shares.map(share => (
            <ShareLinkModalListItem share={share} key={share.id} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
