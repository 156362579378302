import React from 'react';
import { Parser } from 'html-to-react';
import { Avatar, Bubble } from '@reverse-hr/pattern-library';
import { formatDate, formatLinebreak } from '../../../utils/functions';

const ChatBubble = ({
  type,
  modifier,
  isAuthorManager,
  authorAvatar,
  authorName,
  dateTime,
  hourLabel,
  message,
}) => {
  const htmlParser = new Parser();

  const getFormattedDate = () => {
    if (!!dateTime) {
      return formatDate(dateTime, 'dd/MM/yyyy HH:mm')
        .split(' ')
        .join(hourLabel);
    }
  };

  const getSanitizedMessage = message => {
    return message
      ?.replace(/\<|\\u003C/g, '&lt;')
      ?.replace(/\>|\\u003E/g, '&gt;');
  };

  return (
    <div
      data-testid="chat-bubble-container"
      className={`c-app-chat-bubble c-app-chat-bubble--${type} ${modifier}`}
    >
      <div
        data-testid={
          isAuthorManager ? `chat-bubble-manager` : 'chat-bubble-customer'
        }
        className="c-app-chat-bubble__user"
      >
        <Avatar avatar={isAuthorManager ? authorAvatar : null} size={56} />
      </div>

      <div className="c-app-chat-bubble__main">
        <p
          data-testid="chat-bubble-author-and-date"
          className="c-app-chat-bubble__info"
        >
          {authorName} - {getFormattedDate()}
        </p>
        <div data-testid="chat-bubble-text">
          <Bubble corner={type === 'sender' ? 'top-right' : 'top-left'}>
            {htmlParser.parse(formatLinebreak(getSanitizedMessage(message)))}
          </Bubble>
        </div>
      </div>
    </div>
  );
};

ChatBubble.defaultProps = {
  type: 'sender', // 'receiver'
  modifier: '',
  dateTime: null,
  isAuthorManager: false,
};

export default ChatBubble;
