import classNames from 'classnames';
import type { TCodeInputProps } from './CodeInput.types';
import type { FC } from 'react';
import { useCodeInput } from './_hooks/use-code-input';

export const CodeInput: FC<TCodeInputProps> = (props: TCodeInputProps) => {
  const { label, length = 0, error, onChange } = props;
  const inputClassName = classNames('code-input__box-elem', {
    'code-input__box-elem--error': !!error,
  });

  const { handleChange, internalValue, inputElementsRef } = useCodeInput(
    length,
    onChange,
  );

  return (
    <div className="code-input">
      {label && (
        <label htmlFor="code-input-field-0" className="code-input__label">
          {label}
        </label>
      )}
      <div className="code-input__inputs-wrapper">
        {internalValue.current.length > 0 &&
          internalValue.current.map((_, index) => (
            <div key={index} className="code-input__input-wrap">
              <input
                ref={currentElement => {
                  inputElementsRef.current[index] = currentElement;
                }}
                className={inputClassName}
                type="text"
                id={`code-input-field-${index}`}
                maxLength={1}
                minLength={1}
                onChange={() => handleChange(index)}
              />
            </div>
          ))}
      </div>
      {error && <p className="code-input__error">{error}</p>}
    </div>
  );
};
