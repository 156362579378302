import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageLoginForm } from './PageLoginForm';

export interface TPageLoginWithEmail {
  onFormSubmit: (mail: string) => void;
}

export const PageLoginWithEmail: FC<TPageLoginWithEmail> = props => {
  const { onFormSubmit } = props;

  const { t } = useTranslation('', {
    keyPrefix: 'login.requireLink',
  });

  return (
    <>
      <div className="c-page-login__intro">
        <h1 className="c-page-login__title">{t('title')}</h1>
        <p className="c-page-login__description">
          <Trans i18nKey="login.requireLink.description" />
        </p>
      </div>
      <PageLoginForm onSubmit={onFormSubmit} />
    </>
  );
};
