export const INTERVIEWING_FR = {
  header: {
    title: 'Entretiens',
    subtitle:
      'Vous trouverez ici la liste des candidats. Vous pourrez toujours voir à quel stade ils se trouvent et laisser un commentaire pour les faire avancer dans le processus de sélection.',
    subtitle_bis:
      'Vous trouvez ici la liste des candidats. Vous pouvez toujours voir à quel stade ils se trouvent et laisser un commentaire pour les faire avancer dans le processus de sélection.',
    alert:
      'Il y a des candidats en phase finale, pour ne pas les perdre <strong>dissipons rapidement les doutes</strong>',
  },
  referenceHeader: {
    title: 'Vos contacts',
  },
  tabs: {
    see: 'Vu par',
    all: 'Tous',
    active: 'Dans le processus de sélection',
    discarded: 'Ils ne poursuivent pas',
  },
  candidateEvents: {
    seen_by_client: 'Curriculum vu',
    seen_by_client_text: 'Laissez un commentaire',
    presented: 'Curriculum non vu',
    presented_text: 'Lisez et laissez un commentaire',
    liked_by_client: 'Intéressant',
    disliked_by_client: 'Pas intéressant',
    technical_report_incoming: 'En attente du rapport',
    technical_report_published: 'Rapport publié',
    technical_report_published_text: 'Lire le rapport',
    technical_report_filled_in: 'Rapport publié',
    client_interview_to_be_scheduled: 'À interviewer',
    client_interview_to_be_scheduled_text: "En cours d'organisation...",
    client_interview_incoming: 'À interviewer',
    client_interview_incoming_text: 'Téléchargez le dossier',
    client_interview_done: 'Entretien réalisé',
    offer_incoming: 'Faire une offre',
    offer_incoming_text: 'Ne le/la perdons pas!',
    offer_made: 'Offre présentée',
    offer_made_text: "Dans l'attente de {{ name }}",
    offer_accepted: 'Gagnant/e!',
    offer_declined: 'Offre refusée',
    candidate_withdrew: 'Candidature retirée',
    candidate_rejected: "N'avance pas",
  },
};
