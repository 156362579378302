import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLoginWithEmail } from './PageLoginWithEmailField';
import { PageLoginWithCode } from './PageLoginWithCode';

const LoginPageStep = {
  EMAIL_ADDRESS: 'emailAddress',
  VERIFICATION_CODE: 'verificationCode',
} as const;

export const PageLoginBody: FC = () => {
  const [currentLoginStep, setCurrentLoginStep] = useState<string>(
    LoginPageStep.EMAIL_ADDRESS,
  );
  const [userMail, setUserMail] = useState<string | null>(null);

  const handleFirstStepSubmit = (email: string): void => {
    setCurrentLoginStep(LoginPageStep.VERIFICATION_CODE);
    setUserMail(email);
  };

  const { t } = useTranslation('', {
    keyPrefix: 'login.requireLink',
  });

  return (
    <div className="c-page-login__body">
      <div className="c-page-login__wrapper">
        <div className="c-page-login__banner">
          <img
            alt={t('bannerAlt')}
            className="c-page-login__banner-image"
            src="/employer_view/assets/images/login-banner.jpg"
          />
        </div>

        <div className="c-page-login__content">
          {currentLoginStep === LoginPageStep.EMAIL_ADDRESS && (
            <PageLoginWithEmail onFormSubmit={handleFirstStepSubmit} />
          )}
          {currentLoginStep === LoginPageStep.VERIFICATION_CODE && userMail && (
            <PageLoginWithCode userMail={userMail} />
          )}
        </div>
      </div>
    </div>
  );
};
