export const INTERVIEWING_DE = {
  header: {
    title: 'Gespräche',
    subtitle:
      'Hier werden Sie die Liste mit den Bewerbern finden: Sie werden immer die Möglichkeit haben, den aktuellen Status zu sehen und Feedback zu geben, um den Bewerbungsprozess voranzubringen.',
    subtitle_bis:
      'Hier finden Sie die Liste der Kandidaten. Sie können jederzeit sehen, in welchem Stadium sie sich befinden und Feedback geben, um sie im Auswahlprozess voranzubringen.',
    alert:
      'Es befinden sich Personen in der finalen Phase. <strong>Beseitigen Sie schnell alle Unklarheiten</strong>, um diese nicht zu verlieren. ',
  },
  referenceHeader: {
    title: 'Ihre Ansprechpartner',
  },
  tabs: {
    see: 'Anzeigen',
    all: 'Alle',
    active: 'Im Prozess',
    discarded: 'Gestoppt',
  },
  candidateEvents: {
    seen_by_client: 'CV lesen',
    seen_by_client_text: 'Feedback geben',
    presented: 'CV nicht angesehen',
    presented_text: 'Lesen und Feedback geben',
    liked_by_client: 'Interessant',
    disliked_by_client: 'Uninteressant',
    technical_report_incoming: 'Warten auf den Bericht',
    technical_report_published: 'Bericht veröffentlicht',
    technical_report_published_text: 'Bericht lesen',
    technical_report_filled_in: 'Bericht veröffentlicht',
    client_interview_to_be_scheduled: 'Zu interviewen ',
    client_interview_to_be_scheduled_text: 'In Planung',
    client_interview_incoming: 'Zu interviewen',
    client_interview_incoming_text: 'Interview Dossier herunterladen',
    client_interview_done: 'Interview abgeschlossen',
    offer_incoming: 'Ein Angebot machen',
    offer_incoming_text: 'Ihn/Sie nicht verlieren!',
    offer_made: 'Angebot ausgesprochen',
    offer_made_text: 'Warten auf {{ name }}',
    offer_accepted: 'Winner',
    offer_declined: 'Angebot nicht angenommen',
    candidate_withdrew: 'Zieht sich zurück',
    candidate_rejected: 'Fährt nicht fort',
  },
};
